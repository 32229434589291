import { Fragment, useEffect } from 'react'
import { requireAuth, useAuthState } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { Switch, Route, Redirect } from 'react-router-dom'
import withRequiredData from './withRequiredData'
import { PageContextProvider } from 'pageContext'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'
import {
    RequestViewMyRequests,
    RequestViewMyTasks,
    RequestViewAllRequests,
} from 'pages/Requests'
import {
    ItemViewMyRequests,
    ItemViewMyTasks,
    ItemViewAllRequests,
} from 'pages/RequestItems'
import useSubcomponents from 'useSubcomponents'
import Test from 'pages/Test'
import config from 'config'

const App = () => {
    const [{ isWorkflowIRenderable }, authDispatch] = useAuthState()

    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, 30000)
        }
    }, [])

    const {
        hasAccessToRequestViewMyRequestsPage,
        hasAccessToItemViewMyRequestsPage,
        hasAccessToRequestViewMyTasksPage,
        hasAccessToItemViewMyTasksPage,
        hasAccessToRequestViewAllRequestsPage,
        hasAccessToItemViewAllRequestsPage,
    } = useSubcomponents()

    const defaultRedirectTo = hasAccessToRequestViewMyRequestsPage
        ? '/myRequests/businessRequests'
        : hasAccessToItemViewMyRequestsPage
        ? '/myRequests/businessRequestItems'
        : hasAccessToRequestViewMyTasksPage
        ? '/myTasks/businessRequests'
        : hasAccessToItemViewMyTasksPage
        ? '/myTasks/businessRequestItems'
        : hasAccessToRequestViewAllRequestsPage
        ? '/all/businessRequests'
        : hasAccessToItemViewAllRequestsPage
        ? '/all/businessRequestItems'
        : '/forbidden'

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${config.BASE_EID_URL}/ui`}
                    />
                </div>
            )}
            <Switch>
                <Redirect exact from="/" to={defaultRedirectTo} />
                {process.env.NODE_ENV === 'development' && (
                    <Test
                        exact
                        path="/test"
                        render={(props) => (
                            <PageContextProvider key="Test">
                                <Test {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToRequestViewMyRequestsPage && (
                    <Route
                        exact
                        path="/myRequests/businessRequests"
                        render={(props) => (
                            <PageContextProvider key="RequestViewMyRequests">
                                <RequestViewMyRequests {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToRequestViewMyTasksPage && (
                    <Route
                        exact
                        path="/myTasks/businessRequests"
                        render={(props) => (
                            <PageContextProvider key="RequestViewMyTasks">
                                <RequestViewMyTasks {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToRequestViewAllRequestsPage && (
                    <Route
                        exact
                        path="/all/businessRequests"
                        render={(props) => (
                            <PageContextProvider key="RequestViewAllRequests">
                                <RequestViewAllRequests {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}

                {hasAccessToItemViewMyRequestsPage && (
                    <Route
                        exact
                        path="/myRequests/businessRequestItems"
                        render={(props) => (
                            <PageContextProvider key="ItemViewMyRequests">
                                <ItemViewMyRequests {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToItemViewMyTasksPage && (
                    <Route
                        exact
                        path="/myTasks/businessRequestItems"
                        render={(props) => (
                            <PageContextProvider key="ItemViewMyTasks">
                                <ItemViewMyTasks {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToItemViewAllRequestsPage && (
                    <Route
                        exact
                        path="/all/businessRequestItems"
                        render={(props) => (
                            <PageContextProvider key="ItemViewAllRequests">
                                <ItemViewAllRequests {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}

                <Redirect from="" to="/notFound" />
            </Switch>
        </Fragment>
    )
}

export default requireAuth(withRequiredData(App), <Loader />)
