import { Box, makeStyles } from '@material-ui/core'
import { getFullImageUrl } from 'utils'
import { FC, Fragment, useEffect, useState } from 'react'
import { ApproversInfoMessage, Person, Search } from 'components'
import { Pagination } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { PersonSkeleton } from 'components/Skeletons/PersonSkeleton'
import { isNilOrEmpty } from 'packages/core'

const useStyles = makeStyles({
    paginationSteppersContainer: {
        width: '100%',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchContainer: {
        maxWidth: '280px',
        padding: '12px 20px 0px 6px',
    },
})

export type StepApproversListProps = {
    totalApproversCount: number | null
    requestId?: string
    itemId?: string
    stepId: string
    useDataHook: any
}

const take = 10

export const StepApproversList: FC<StepApproversListProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1)

    const { latestData, isLoading } = props.useDataHook(
        props.itemId ? props.itemId : props.requestId,
        props.stepId,
        (page - 1) * take,
        take,
        searchTerm,
    )
    const approvers = latestData ? latestData.data : undefined

    let showInfoMessage = false
    if (approvers) {
        showInfoMessage =
            props.totalApproversCount === 0 ||
            props.totalApproversCount !== latestData.totalCount
    }

    const handlePageChange = (value: any) => {
        setPage(value)
    }

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    if (isLoading || !props.stepId) {
        return <ApproversLoader />
    }

    const showSearch = approvers?.length > 0 || searchTerm.length > 0

    return (
        <Fragment>
            {showSearch && (
                <Box className={classes.searchContainer}>
                    <Search
                        fullWidth
                        outlined
                        placeholder={t('MyTasks_Search')}
                        value={searchTerm}
                        handleClear={(value: any) => {
                            handlePageChange(1)
                            setSearchTerm(value)
                        }}
                        handleSearch={(value: any) => {
                            if (value !== searchTerm) {
                                handlePageChange(1)
                                setSearchTerm(value)
                            }
                        }}
                    />
                </Box>
            )}

            {showInfoMessage && isNilOrEmpty(searchTerm) && (
                <Box
                    marginY="20px"
                    marginRight="40px"
                    paddingX="20px"
                    paddingLeft="6px"
                >
                    <ApproversInfoMessage
                        totalCount={props.totalApproversCount}
                        visibleCount={latestData.totalCount}
                    />
                </Box>
            )}

            {!approvers && <ApproversLoader />}

            {approvers && approvers.length === 0 && (
                <Box
                    marginY={'20px'}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    {t('MyTasks_NoDataFound')}
                </Box>
            )}

            {approvers && approvers.length > 0 && (
                <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    justifyContent={'flex-start'}
                    marginTop="20px"
                >
                    {approvers.map((a: any, index: any) => (
                        <Box key={index + a.id} maxWidth="280px" marginX="8px">
                            <Person
                                size="medium"
                                name={a.friendlyName}
                                imgSrc={getFullImageUrl(a.imageThumbUrl)}
                                style={{ color: '#5d6870' }}
                            />
                        </Box>
                    ))}
                </Box>
            )}

            {numberOfPages > 1 && (
                <Box className={classes.paginationSteppersContainer}>
                    <Pagination
                        count={numberOfPages}
                        size="small"
                        page={page}
                        onChange={(_: any, value: any) =>
                            handlePageChange(value)
                        }
                    />
                </Box>
            )}
        </Fragment>
    )
}

const ApproversLoader = () => {
    return (
        <Box
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'flex-start'}
            marginY="20px"
        >
            <Box maxWidth="280px" marginX="8px">
                <PersonSkeleton />
            </Box>

            <Box maxWidth="280px" marginX="8px">
                <PersonSkeleton />
            </Box>

            <Box maxWidth="280px" marginX="8px">
                <PersonSkeleton />
            </Box>
        </Box>
    )
}
