import React, { useState } from 'react'
import { useRequestItemTypes } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Box, Collapse } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ItemTypeRequestItems from './ItemTypeRequestItems'
import { Trans } from 'react-i18next'
import { HorizontalTabs } from 'packages/eid-ui'
import { getStatusColor } from 'utils'
import { useTableStyles, Tabs, RoleCount, ExpandIcon } from './utils'

const RequestItemsSummary = ({ businessRequest, id }) => {
    const classes = useTableStyles()

    const [currentTab, setCurrentTab] = useState('All')

    const { t } = useTranslation()
    const { data, isLoading } = useRequestItemTypes(id)

    const [expanded, setExpanded] = useState([])

    const isExpanded = (item) =>
        expanded.find((i) => i.itemTypeId === item.itemTypeId)

    const handleExpand = (item, section) => {
        const expandedIndex = expanded.findIndex(
            (i) => i.itemTypeId === item.itemTypeId,
        )

        const expandedItem = expanded.find(
            (i) => i.itemTypeId === item.itemTypeId,
        )

        let newExpanded = []

        if (!expandedItem) {
            newExpanded = newExpanded.concat(expanded, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section !== section) {
            let filteredArray = expanded.filter(
                (i) => i.itemTypeId !== item.itemTypeId,
            )
            newExpanded = newExpanded.concat(filteredArray, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section === section) {
            newExpanded = [...expanded]
            newExpanded.splice(expandedIndex, 1)
        }

        setExpanded(newExpanded)
    }

    const renderCollapsedContent = (item) => {
        const expandedItem = isExpanded(item)

        return (
            <>
                {expandedItem && expandedItem.section === 'details' ? (
                    <ItemTypeRequestItems
                        businessRequest={businessRequest}
                        requestId={id}
                        itemTypeId={item.itemTypeId}
                        currentTab={currentTab}
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }

    const getSumOfItems = () =>
        data
            .map((d) => d.total)
            .reduce((a, b) => {
                return a + b
            }, 0)

    return (
        <>
            <Box className={classes.table} width="100%">
                <Box className="TableHead" width="100%">
                    <Box className={classes.tableHeaderRow}>
                        <Box className={classes.tableHeaderCell} width="100%">
                            {t('MyTasks_BusinessRequestItems')}{' '}
                            {data && <>({getSumOfItems()})</>}
                        </Box>
                    </Box>
                </Box>

                <Box className="TableBody" width="100%" overflow="auto">
                    {isLoading ? (
                        <Box className="TableRow">
                            <Box className="TableCell">
                                <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    height={64}
                                />
                            </Box>
                        </Box>
                    ) : data.length === 0 ? (
                        <Box
                            className={classes.tableRow}
                            justifyContent="center"
                            padding="14px"
                        >
                            <Box className={classes.tableCell}>
                                <Trans i18nKey="MyTasks_NoDataFound" />
                            </Box>
                        </Box>
                    ) : (
                        <>
                            {data.map((item, index) => (
                                <React.Fragment key={item.itemTypeId}>
                                    <Box
                                        className={classes.tableRow}
                                        key={index}
                                    >
                                        <Box className={classes.firstCell}>
                                            {item.itemTypeFriendlyName}
                                        </Box>

                                        <Box className={classes.tabsCell}>
                                            <Tabs
                                                variant="standard"
                                                value={currentTab}
                                                disabled={!isExpanded(item)}
                                                onChange={(_, value) => {
                                                    setCurrentTab(value)
                                                }}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                            >
                                                <HorizontalTabs.Tab
                                                    key={1}
                                                    value={'All'}
                                                    disabled={!isExpanded(item)}
                                                    label={
                                                        <RoleCount
                                                            label={t(
                                                                'MyTasks_All',
                                                            )}
                                                            count={item.total}
                                                        />
                                                    }
                                                />
                                                <HorizontalTabs.Tab
                                                    key={2}
                                                    value={'Approved'}
                                                    disabled={!isExpanded(item)}
                                                    label={
                                                        <RoleCount
                                                            label={t(
                                                                'MyTasks_Approved',
                                                            )}
                                                            count={
                                                                item.approved
                                                            }
                                                            color={getStatusColor(
                                                                'approved',
                                                            )}
                                                        />
                                                    }
                                                />

                                                <HorizontalTabs.Tab
                                                    key={3}
                                                    value={'Rejected'}
                                                    disabled={!isExpanded(item)}
                                                    label={
                                                        <RoleCount
                                                            label={t(
                                                                'MyTasks_Rejected',
                                                            )}
                                                            count={
                                                                item.rejected
                                                            }
                                                            color={getStatusColor(
                                                                'rejected',
                                                            )}
                                                        />
                                                    }
                                                />

                                                <HorizontalTabs.Tab
                                                    key={4}
                                                    value={'Open'}
                                                    disabled={!isExpanded(item)}
                                                    label={
                                                        <RoleCount
                                                            label={t(
                                                                'MyTasks_Open',
                                                            )}
                                                            count={item.open}
                                                            color={getStatusColor(
                                                                'open',
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Tabs>
                                        </Box>

                                        <Box
                                            className={classes.tableCell}
                                            style={{
                                                maxWidth: '260px',
                                                minWidth: '120px',
                                                paddingRight: '32px',
                                                display: 'inline-table',
                                                whiteSpace: 'nowrap',
                                            }}
                                            align="right"
                                        >
                                            <ExpandIcon
                                                expanded={isExpanded(item)}
                                                onClick={() => {
                                                    if (!isExpanded(item)) {
                                                        setCurrentTab('All')
                                                    }
                                                    handleExpand(
                                                        item,
                                                        'details',
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            className={
                                                Boolean(isExpanded(item))
                                                    ? classes.expansionCellActive
                                                    : classes.expansionCell
                                            }
                                        >
                                            <Collapse
                                                in={Boolean(isExpanded(item))}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                {renderCollapsedContent(item)}
                                            </Collapse>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default RequestItemsSummary
