import React, { Fragment } from 'react'
import {
    TableHead,
    TableSortLabel,
    TableRow,
    TableCell,
    Link,
    Box,
    makeStyles,
    styled,
    Table,
} from '@material-ui/core'
import { Tooltip, StatusIndicator, RiskIndicator } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import { TextFormatter, Person, SubjectTypeAttribute } from 'components'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'packages/core'
import moment from 'moment'
import { getFullImageUrl } from 'utils'

const maxCharacters = 100

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
    black: '#282828',
}

const EmptyRow = styled(TableRow)({
    border: 'none !important',
})

const EmptyCell = styled(TableCell)({
    border: 'none !important',
    padding: '10px',
})

const useHeaderRowStyles = makeStyles({
    root: {
        backgroundColor: '#fbfbfd',

        height: '32px',

        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
        '& >th:first-child': {
            borderBottomLeftRadius: '4px !important',
            borderTopLeftRadius: '4px !important',
        },
        '& >th:last-child': {
            borderBottomRightRadius: '4px !important',
            borderTopRightRadius: '4px !important',
        },

        '& .MuiTableCell-head': {
            padding: '10px ',
            fontSize: '13px',
            lineHeight: 1.33,
            color: colors.grey,
        },
    },
})

const useSortLabelStyles = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
        },
        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})

const useHeaderCellStyles = makeStyles({
    root: ({ style }) => ({
        border: 'none !important',
        padding: '16px 10px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
    }),
})

const useCellStyles = makeStyles({
    root: ({ style }) => ({
        border: 'none ',
        padding: '16px 0px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        fontSize: '13px',
        lineHeight: 1.5,
        color: colors.black,
        '& >a': {
            color: style && style.color ? style.color : colors.primary,
        },

        ...style,
    }),
})

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const CustomTableCell = ({ style, link, children, onClick, url, ...rest }) => {
    const cellClasses = useCellStyles({ style })

    return (
        <TableCell
            align="left"
            classes={cellClasses}
            onClick={onClick}
            {...rest}
        >
            {!link ? (
                children
            ) : (
                <Link
                    href={url}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {children}
                </Link>
            )}
        </TableCell>
    )
}

export const RequestItemsTableHead = ({ attributes }) => {
    const { t } = useTranslation()
    const [{ sortBy, sortOrder }, dispatch] = usePageContext()

    const headerRowClasses = useHeaderRowStyles()
    const headerCellClasses = useHeaderCellStyles()
    const sortLabelClasses = useSortLabelStyles()

    const handleSort = (heading) => {
        const sortByName = heading.sortBy ? heading.sortBy : heading.name
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                sortBy: sortByName,
                sortOrder:
                    sortBy !== sortByName
                        ? 'desc'
                        : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                        ? 'desc'
                        : 'asc',
            },
        })
    }
    const filteredAttributes = attributes.filter(
        (a) => a.placement !== 'TagRow',
    )

    return (
        <TableHead>
            <TableRow classes={headerRowClasses}>
                {filteredAttributes.map((heading, index) => {
                    const sortByName = heading.sortBy
                        ? heading.sortBy
                        : heading.name
                    return (
                        <TableCell
                            key={'pd-head-cell-' + index}
                            classes={headerCellClasses}
                            style={heading.tableStyles?.headerCellStyles}
                            align="left"
                        >
                            {!heading.sortable ? (
                                <span style={{ display: 'flex' }}>
                                    {heading.label}
                                </span>
                            ) : (
                                <span style={{ display: 'flex' }}>
                                    <Tooltip
                                        title={
                                            sortBy === sortByName &&
                                            sortOrder === 'desc'
                                                ? t('MyTasks_SortAscending')
                                                : t('MyTasks_SortDescending')
                                        }
                                    >
                                        <TableSortLabel
                                            classes={sortLabelClasses}
                                            active={sortBy === sortByName}
                                            direction={
                                                sortBy === sortByName
                                                    ? sortOrder
                                                    : 'asc'
                                            }
                                            onClick={() => handleSort(heading)}
                                        >
                                            {t(heading.label)}
                                        </TableSortLabel>
                                    </Tooltip>
                                </span>
                            )}
                        </TableCell>
                    )
                })}
            </TableRow>

            <EmptyRow>
                <EmptyCell colSpan={filteredAttributes.length} />
            </EmptyRow>
        </TableHead>
    )
}

const useRowStyles = makeStyles({
    root: {
        position: 'relative',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        minHeight: '40px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
        '& >td:first-child': {
            borderBottomLeftRadius: '4px !important',
            borderTopLeftRadius: '4px !important',
        },
        '& >td:last-child': {
            borderBottomRightRadius: '4px !important',
            borderTopRightRadius: '4px !important',
        },

        '&:hover': {
            '& .Details-button': {
                backgroundColor: colors.primary,
                color: '#ffffff',
                cursor: 'pointer',
            },
        },
    },
})

const DetailsButton = ({ styles, onClick }) => (
    <span style={styles}>
        <StyledLink
            className="Details-button"
            component="button"
            onClick={(e) => {
                onClick()
                e.stopPropagation()
            }}
        >
            +
        </StyledLink>
    </span>
)

const StyledLink = styled(Link)({
    borderRadius: '50%',
    fontWeight: 'normal',
    width: '20px',
    height: '20px',
    backgroundColor: '#ffffff',
    border: '1px solid #aab0b4',
    color: '#aab0b4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
        backgroundColor: colors.primary,
        color: '#ffffff',
        cursor: 'pointer',
    },

    '&:focus': {
        outline: 'none',
    },
})

const CellDateTime = ({ value }) => (
    <>{value ? moment.utc(value).local().format('LLL') : '-'}</>
)

const FulfillmentStatusIndicator = ({ attribute, item }) => {
    const { t } = useTranslation()

    const itemStatus = item.preApproved ? 'PreApproved' : item.status
    const value = item[attribute.component.name]

    return (
        <Fragment>
            {(itemStatus === 'Approved' || itemStatus === 'PreApproved') &&
                value && (
                    <StatusIndicator
                        label={`${t('MyTasks_Fulfillment')} ${value}`}
                        size="small"
                        style={{
                            minWidth: '200px',
                            height: '16px',
                        }}
                    />
                )}
        </Fragment>
    )
}

const TagRow = styled(TableRow)({
    width: '100%',
    height: '16px',
})

const TagRowCell = styled(TableCell)({
    border: 'none !important',
    padding: '0px',

    '& span': {
        display: 'flex',
        marginBottom: '-8px',
        zIndex: 1,
        '& span': {
            margin: '0px 8px',
        },
    },
})

const StyledTable = styled(Table)({
    border: 'none !important',
    boxShadow: 'none !important',
    borderCollapse: 'separate',
})

export const RequestItemsTable = StyledTable

const BorderedRow = styled(TableRow)({
    border: 'none !important',
})

const BorderedCell = styled(TableCell)({
    padding: '0px 10px',
    overflowWrap: 'break-word',
    wordBreak: 'normal',
    overflow: 'hidden',
    color: colors.grey,
    borderRadius: '8px !important',
    borderImageSource:
        'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
    borderImageSlice: 1,
})

export const RequestItemRow = ({ attributes, item }) => {
    const { t } = useTranslation()
    const rowClasses = useRowStyles()
    const history = useHistory()
    const query = useQuery()

    const renderButton = (a) => {
        switch (a.button.name) {
            case 'DetailsButton':
                return (
                    <DetailsButton
                        onClick={() => {}}
                        styles={a.button.styles}
                    />
                )

            default:
                return <></>
        }
    }

    const renderComponent = (a, item) => {
        switch (a.component.componentName) {
            case 'StatusIndicator':
                const itemStatus = item.preApproved
                    ? 'PreApproved'
                    : item.status
                return (
                    <StatusIndicator
                        className="Status-Indicator"
                        style={a.component.styles}
                        variant={itemStatus}
                        label={t(`MyTasks_${itemStatus}`)}
                        {...a.component.props}
                    />
                )
            case 'RiskIndicator':
                return (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {item[a.component.name] ? (
                            <RiskIndicator
                                variant={item[a.component.name]}
                                label={t(
                                    `MyTasks_${item[a.component.name]}Risk`,
                                )}
                                {...a.component.props}
                            />
                        ) : (
                            <>-</>
                        )}
                    </Box>
                )

            case 'CellDateTime':
                return <CellDateTime value={item[a.name]} />

            case 'FulfillmentStatusIndicator':
                return <FulfillmentStatusIndicator attribute={a} item={item} />

            case 'SubjectType':
                const subjectType = item.subjectType
                return (
                    <SubjectTypeAttribute
                        subjectType={subjectType}
                        item={item}
                        personComponentProps={{ ...a.component.props }}
                    />
                )

            default:
                return <></>
        }
    }

    const renderBrStatus = (item) => {
        const requestStatus = item.businessRequestStatus
        return (
            <Fragment>
                {requestStatus === 'Expired' && (
                    <StatusIndicator
                        style={{
                            display: 'normal',
                            minWidth: '200px',
                            height: '16px',
                        }}
                        variant={requestStatus}
                        label={t(`MyTasks_BusinessRequestExpired`)}
                        size="small"
                    />
                )}
            </Fragment>
        )
    }

    const filteredAttributes = attributes.filter(
        (a) => a.placement !== 'TagRow',
    )
    const tagRowAttributes = attributes.filter((a) => a.placement === 'TagRow')
    return (
        <React.Fragment>
            {
                <TagRow>
                    <TagRowCell colSpan={filteredAttributes.length}>
                        <span>
                            {tagRowAttributes.map((a, index) => (
                                <Fragment key={`tag-row-cell-${index}`}>
                                    {a.component && renderComponent(a, item)}

                                    {a.renderBrStatus && renderBrStatus(item)}
                                </Fragment>
                            ))}
                        </span>
                    </TagRowCell>
                </TagRow>
            }

            <TableRow key={item.id} classes={rowClasses}>
                {filteredAttributes.map((a, index) => {
                    return (
                        <CustomTableCell
                            key={item.id + `${index}`}
                            style={a.tableStyles?.styles}
                            link={a.link}
                            url={a.url}
                            onClick={() => {
                                query.set('id', item.id)
                                history.push(
                                    `${
                                        history.location.pathname
                                    }?${query.toString()}`,
                                )
                            }}
                        >
                            <Box
                                display="block"
                                alignItems="center"
                                paddingX="10px"
                                width={a.tableStyles?.styles?.width}
                            >
                                {a.avatar && (
                                    <Person
                                        name={item[a.name]}
                                        imgSrc={getFullImageUrl(
                                            item[a.imgProp],
                                        )}
                                        tooltipProps={tooltipProps}
                                        style={a?.tableStyles?.textStyles}
                                    />
                                )}
                                {!a.hideValue && (
                                    <TextFormatter
                                        value={item[a.name]}
                                        tooltipProps={tooltipProps}
                                        maxCharacters={maxCharacters}
                                        style={a.tableStyles?.styles}
                                    ></TextFormatter>
                                )}
                                {a.button && renderButton(a)}
                                {a.component && renderComponent(a, item)}
                            </Box>
                        </CustomTableCell>
                    )
                })}
            </TableRow>

            <BorderedRow>
                <BorderedCell
                    colSpan={filteredAttributes.length}
                    onClick={() => {}}
                ></BorderedCell>
            </BorderedRow>

            <EmptyRow>
                <EmptyCell colSpan={filteredAttributes.length} />
            </EmptyRow>
        </React.Fragment>
    )
}
