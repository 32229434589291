import { Box, makeStyles, styled } from '@material-ui/core'
import {
    ClaimControl,
    DelegateMenuButton,
    DelegationsHistory,
    ItemDetails,
    Loader,
    ProcessSteps,
} from 'components'
import {
    useAccessRequestPolicies,
    useApplicationSettings,
    useCancelRequest,
    useCurrentPerson,
    useRefreshRequestRisks,
    useRequestApprovalSteps,
    useRequestDelegationsHistory,
    useRequestRisks,
} from 'hooks'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Overview from './Overview'
import RequestApprovers from './RequestApprovers'
import RequestComments from './RequestComments'
import RequestItemsSummary from './RequestItemsSummary'
import useSubcomponents from 'useSubcomponents'
import { isNilOrEmpty } from 'packages/core'
import { smallScreenWidth } from 'utils'
import { RiskIndicator } from 'packages/eid-ui'
import { CancelationButton } from 'components/Button/CancelationButton'
import { RequestApprovalDecisionPrompt } from './RequestApprovalDecisionPrompt'
import RequestToDoTabs from './RequestToDoList'
import { WorkflowDecisionModal } from 'components/WorkflowDecisionModal'
import { RefreshButton } from 'components/Button/RefreshButton'
import { RiskViolationsTabLabel } from './RiskViolationsTabLabel'
import { ViolationsByItems } from './ViolationsByItems'
import { RisksWithViolations } from 'components/V2/RisksWithViolations'

const TabsContainerBox = styled(Box)({
    backgroundColor: '#fbfbfd',
    borderBottom: '1px solid #d8dadd',

    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'auto',

    '& .Tabs-root': {
        display: 'flex',
    },

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column-reverse',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',

        '& .Tabs-root': {
            marginLeft: '-20px',
        },
    },
})

const OverflowContainer = styled(Box)({
    [`@media (max-width:${smallScreenWidth})`]: {
        overflowX: 'auto',
    },
})

const useStyles = makeStyles({
    subHeader: {
        position: 'sticky',
        top: '0px',
        zIndex: 200,
    },
    subHeaderContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '7px 20px',
        display: 'flex',
        alignItems: 'center',
    },
    riskIndicator: {
        backgroundColor: '#fdf1f3',
        opacity: 1,
        fontWeight: 'bold',
    },
})

const RequestDetails = ({ data, header }) => {
    const { t } = useTranslation()
    const { data: requestAccessPolicies } = useAccessRequestPolicies()

    const { data: appConfig } = useApplicationSettings()
    const classes = useStyles()

    const { data: currentPerson } = useCurrentPerson()

    const [activeTab, setActiveTab] = useState('overview')

    const {
        hasAccessToDelegationsHistoryControl,
        hasAccessToRequestProcessStepsControl,
        hasAccessToRequestCurrentStepApproversControl,
        hasAccessToRequestClaimControl,
        hasAccessToRequestDelegateControl,
        hasAccessToAdminCancelRequestControl,
        hasAccessToCancelRequestControl,
        hasAccessToRefreshViolationsControl,
        canSeeRiskViolationsByItems,
        canSeeRiskViolationsByRisks,
    } = useSubcomponents()

    let defaultSelected

    if (canSeeRiskViolationsByItems) defaultSelected = 'ByItems'
    if (canSeeRiskViolationsByRisks) defaultSelected = 'ByRisks'

    const [selected, setSelected] = useState(defaultSelected)

    const isClaimedOrAssigned = !isNilOrEmpty(data.claimedApproverId)

    const isClaimedOrClaimable =
        data.isGlobalStep && isNilOrEmpty(data.assignedByPersonId)

    const isBrNotActionable =
        data.status === 'Expired' || data.status === 'Canceled'

    const isDelegatable = data.isGlobalStep

    const [cancelRequest, { isLoading: isCanceling }] = useCancelRequest(
        data.id,
    )
    const [
        refreshRequestRisks,
        { isLoading: isRefreshing },
    ] = useRefreshRequestRisks(data.id)
    if (!requestAccessPolicies) {
        return <Loader />
    }
    return (
        <>
            {header}

            <Box
                className={classes.subHeader}
                style={{
                    backgroundImage: appConfig.style.colors.primary?.gradient,
                    backgroundColor: appConfig.style.colors.primary?.header,
                }}
            >
                <Box className={classes.subHeaderContent}>
                    {hasAccessToRefreshViolationsControl && (
                        <RefreshButton
                            onClick={refreshRequestRisks}
                            isRefreshing={isRefreshing}
                        />
                    )}

                    {data.riskLevel && (
                        <Box marginLeft={'15px'}>
                            <RiskIndicator
                                variant={data.riskLevel}
                                label={t(`MyTasks_${data.riskLevel}Risk`)}
                                className={classes.riskIndicator}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <TabsContainerBox>
                <Box marginLeft="-13px" className="Tabs-root">
                    <ItemDetails.Tabs
                        variant="scrollable"
                        scrollButtons="on"
                        value={activeTab}
                        onChange={(_, value) => setActiveTab(value)}
                    >
                        <ItemDetails.Tabs.Tab
                            value="overview"
                            label={t('MyTasks_Overview')}
                        />
                        {hasAccessToRequestProcessStepsControl && (
                            <ItemDetails.Tabs.Tab
                                value="processSteps"
                                label={t('MyTasks_ProcessSteps')}
                            />
                        )}
                        {hasAccessToRequestCurrentStepApproversControl &&
                            !isClaimedOrAssigned &&
                            data.approverCount !== null &&
                            data.currentApprovalStepId && (
                                <ItemDetails.Tabs.Tab
                                    value="currentStepApprovers"
                                    label={t('MyTasks_CurrentStepApprovers')}
                                />
                            )}
                        {hasAccessToDelegationsHistoryControl && (
                            <ItemDetails.Tabs.Tab
                                value="delegationsHistory"
                                label={t('MyTasks_Delegations')}
                            />
                        )}
                        {data.riskCount > 0 &&
                            (canSeeRiskViolationsByItems ||
                                canSeeRiskViolationsByRisks) && (
                                <ItemDetails.Tabs.Tab
                                    value={'riskViolations'}
                                    label={
                                        <RiskViolationsTabLabel
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    }
                                />
                            )}
                    </ItemDetails.Tabs>
                </Box>
                <Box display="flex" alignItems="center">
                    {hasAccessToRequestClaimControl &&
                        isClaimedOrClaimable &&
                        data.allowAssignApprover && (
                            <Box marginRight="8px">
                                <ClaimControl
                                    disabled={isBrNotActionable}
                                    page="Requests"
                                    isApprover={data.isApprover}
                                    requestId={data.id}
                                    currentApprovalStepId={
                                        data.currentApprovalStepId
                                    }
                                    claimedApproverId={data.claimedApproverId}
                                    claimedApproverFriendlyName={
                                        data.claimedApproverFriendlyName
                                    }
                                />
                            </Box>
                        )}
                    {hasAccessToRequestDelegateControl && isDelegatable && (
                        <DelegateMenuButton
                            page="Requests"
                            disabled={isBrNotActionable}
                            requestId={data.id}
                            isApprover={data.isApprover}
                            currentApprovalStepId={data.currentApprovalStepId}
                            assignedByPersonId={data.assignedByPersonId}
                            assignedByPersonFriendlyName={
                                data.assignedByPersonFriendlyName
                            }
                            claimedApproverId={data.claimedApproverId}
                            claimedApproverFriendlyName={
                                data.claimedApproverFriendlyName
                            }
                            allowAddApprover={data.allowAddApprover}
                            allowAssignApprover={data.allowAssignApprover}
                        />
                    )}
                </Box>
            </TabsContainerBox>
            <div style={{ marginBottom: '30px' }}>
                {activeTab === 'overview' && <Overview data={data} />}
                {activeTab === 'processSteps' && (
                    <OverflowContainer>
                        <ProcessSteps
                            useDataHook={useRequestApprovalSteps}
                            requestId={data.id}
                            isCanceled={data.status === 'Canceled'}
                        />
                    </OverflowContainer>
                )}
                {activeTab === 'currentStepApprovers' && (
                    <RequestApprovers
                        id={data.id}
                        totalCount={data.approverCount}
                    />
                )}
                {activeTab === 'delegationsHistory' && (
                    <OverflowContainer>
                        <DelegationsHistory
                            page="Request"
                            useDataHook={useRequestDelegationsHistory}
                            requestId={data.id}
                        />
                    </OverflowContainer>
                )}
                {activeTab === 'riskViolations' && (
                    <Fragment>
                        {selected === 'ByItems' && (
                            <ViolationsByItems requestId={data.id} />
                        )}
                        {selected === 'ByRisks' && (
                            <RisksWithViolations
                                page={'Requests'}
                                requestId={data.id}
                                requestIdOrItemId={data.id}
                                useDataHook={useRequestRisks}
                            />
                        )}
                    </Fragment>
                )}
            </div>
            <RequestToDoTabs data={data} />

            {data &&
                !isBrNotActionable &&
                data.isApprovable &&
                (data.decisionWorkflowName ? (
                    <Box
                        marginY={'24px'}
                        marginX={'28px'}
                        display={'flex'}
                        justifyContent={'flex-end'}
                    >
                        <WorkflowDecisionModal
                            page={'Requests'}
                            requestId={data.id}
                            currentApprovalStepId={data.currentApprovalStepId}
                            decisionWorkflowName={data.decisionWorkflowName}
                        />
                    </Box>
                ) : (
                    <RequestApprovalDecisionPrompt
                        businessRequest={data}
                        id={data.id}
                    />
                ))}
            <Box width="100%" overflow="auto">
                <RequestItemsSummary businessRequest={data} id={data.id} />
            </Box>
            <Box marginY="18px">
                <RequestComments requestId={data.id} businessRequest={data} />
            </Box>
            {data.isCancellable &&
                (hasAccessToAdminCancelRequestControl ||
                    (hasAccessToCancelRequestControl &&
                        data.initiatorPersonId === currentPerson.id)) && (
                    <Box marginY={'32px'} marginX={'32px'}>
                        <CancelationButton
                            confirmationMessage={t(
                                'MyTasks_RequestCancelationConfirmation',
                            )}
                            isCanceling={isCanceling}
                            onClick={cancelRequest}
                        >
                            {t('MyTasks_CancelRequest')}
                        </CancelationButton>
                    </Box>
                )}
        </>
    )
}

export default RequestDetails
