import { Pagination as MuiPagination } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core'
import DefaultItem from './PaginationItem'

const defaultListStyles = {
    borderRadius: '5px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    '& >li:first-child': {
        '& > button': {
            borderRadius: '5px 0px 0px 5px !important',
        },
    },
    '& >li:last-child': {
        '& > button': {
            borderRadius: '0px 5px 5px 0px !important',
            borderRight: '1px solid #ebebed',
        },
    },
}

const roundedStyles = {
    backgroundColor: '#ffffff',
    borderRadius: '12px',

    height: '24px',
    fontSize: '12px !important',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.08)',

    '& >li': {
        '& > button': {
            borderRadius: '50%',
            height: '15px',
            minWidth: '15px',
            margin: '0px 8px !important',
            fontSize: '12px !important',
            border: 'none !important',
        },
        '& > div': {
            borderRadius: '50%',
            height: '15px',
            minWidth: '15px',
            border: 'none !important',
            margin: '0px 8px !important',
        },
    },
}

const useStyles = makeStyles({
    ul: (props) => ({
        ...props.styles,
    }),
})

const Pagination = (props) => {
    const {
        size,
        listStyles,
        PaginationItemComponent: PaginationItem,
        variant,
        parentIndex,
        ...rest
    } = props

    const styles = variant === 'rounded' ? roundedStyles : listStyles
    const classes = useStyles({ styles, variant })
    return (
        <MuiPagination
            style={{ zIndex: parentIndex ? parentIndex : 9999 }}
            classes={classes}
            renderItem={(item) => <PaginationItem size={size} {...item} />}
            size={size}
            {...rest}
        />
    )
}

Pagination.defaultProps = {
    listStyles: defaultListStyles,
    PaginationItemComponent: DefaultItem,
}

export default Pagination
