import {
    Box,
    Collapse,
    makeStyles,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
    ApproversList,
    BusinessRequestItemComments,
    Button,
    DecisionComment,
    DecisionMenu,
    DelegateMenuIconButton,
} from 'components'
import {
    useRequestItemApprovers,
    useRequestItemRisks,
    useSaveRequestToDoItemDecision,
} from 'hooks'
import { isNilOrEmpty, isWhitespace } from 'packages/core'
import React, { Fragment, useReducer, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import { APPROVAL_COMMENT_MAX_LENGTH, smallScreenWidth } from 'utils'
import { WorkflowDecisionModal } from 'components/WorkflowDecisionModal'
import config from 'config'
import { RisksWithViolations } from 'components/V2/RisksWithViolations'
import { ViolatingItemDecision } from './ViolatingItemDecision'

const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'SET_DECISION':
            const newState = [...state]
            const itemIndex = newState.findIndex(
                (i) => i.id === payload.item.id,
            )

            let newItem
            if (payload.decisionId === null) {
                if (itemIndex >= 0) {
                    newItem = { ...newState[itemIndex] }
                    newState.splice(itemIndex, 1)
                    return newState
                } else {
                    return newState
                }
            } else {
                if (itemIndex >= 0) {
                    newItem = { ...newState[itemIndex] }
                    newState.splice(itemIndex, 1)
                    newItem.decisionId = payload.decisionId
                } else {
                    newItem = {
                        id: payload.item.id,
                        stepId: payload.item.currentApprovalStepId,
                        decisionId: payload.decisionId,
                    }
                }
            }

            return [...newState, newItem]

        case 'SET_MULTIPLE_DECISIONS':
            const newDecisions = [...state]
            for (let i = 0; i < payload.items.length; i++) {
                const currentItem = payload.items[i]
                const existingItemIndex = newDecisions.findIndex(
                    (i) => i.id === currentItem.id,
                )

                if (existingItemIndex >= 0) {
                    const existingItemCopy = {
                        ...newDecisions[existingItemIndex],
                    }
                    newDecisions.splice(existingItemIndex, 1)
                    existingItemCopy.decisionId = payload.decisionId
                    newDecisions.push(existingItemCopy)
                } else {
                    newDecisions.push({
                        id: currentItem.id,
                        stepId: currentItem.currentApprovalStepId,
                        decisionId: payload.decisionId,
                    })
                }
            }
            return newDecisions

        case 'SET_COMMENT':
            const commentsNewState = [...state]
            const commentItemIndex = commentsNewState.findIndex(
                (i) => i.id === payload.item.id,
            )
            let commentNewItem
            if (commentItemIndex >= 0) {
                commentNewItem = { ...commentsNewState[commentItemIndex] }
                commentsNewState.splice(commentItemIndex, 1)
                commentNewItem.comment = payload.comment
            }
            return [...commentsNewState, commentNewItem]

        case 'CLEAR':
            return []

        default:
            return []
    }
}

const BusinessRequestItemApprovers = ({ requestId, itemId, totalCount }) => {
    const take = 9
    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = React.useState(1)

    const handlePageChange = (value) => {
        setPage(value)
    }

    const { latestData } = useRequestItemApprovers(
        requestId,
        itemId,
        (page - 1) * take,
        take,
        searchTerm,
    )

    const isLoading = !Boolean(latestData)

    const approvers = latestData ? latestData.data : undefined

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    return (
        <ApproversList
            totalCount={totalCount}
            visibleCount={latestData && latestData.totalCount}
            isLoading={isLoading}
            data={approvers}
            page={page}
            handlePageChange={handlePageChange}
            numberOfPages={numberOfPages}
            searchTerm={searchTerm}
            handleSearch={setSearchTerm}
        />
    )
}

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {},
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableRowExpanded: {
        border: ' solid 1px #efeff1',
        borderBottom: 'none',
    },

    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    expansionCell: {
        padding: '0px',
        border: 'none',
    },

    expansionCellActive: {
        padding: '14px 0px',
        border: 'none',
        backgroundColor: '#efeff1',
    },
    expansionRow: {
        border: 'none',
    },
    expansionRowActive: {
        borderBottom: 'none',
        borderTop: 'none',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#307fc1 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },

    approversContainer: {
        maxWidth: '1000px',

        [`@media (max-width:${smallScreenWidth})`]: {
            width: '100vw',
        },
    },

    timeconstraintbtn: {
        height: '60px',
        overflow: 'hidden',
        textAlign: 'center',
        cursor: 'pointer',
        '& svg': {
            margin: '20px auto !important',
        },
    },
    violationbtn: {
        height: '60px',
        overflow: 'hidden',
        textAlign: 'center',
        cursor: 'pointer',
        '& button': {
            marginTop: '9px',
        },
    },
    approverbtn: {
        height: '60px',
        overflow: 'hidden',
        textAlign: 'center',
        '& svg': {
            marginTop: '9px',
        },
    },
    commentsbtn: {
        height: '60px',
        overflow: 'hidden',
        textAlign: 'center',
        '& svg': {
            marginTop: '9px',
        },
    },
    requestsbtn: {
        height: '60px',
        overflow: 'hidden',
        textAlign: 'center',
        '& svg': {
            marginTop: '9px',
        },
    },
    actionbutton: {
        display: 'flex',
        justifyContent: 'space-between',
    },
})

export const PendingItems = ({
    businessRequest,
    id,
    data,
    isLoading,
    callApiOnEveryChange,
    totalCount,
    onSuccess,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [
        saveBusinessRequestItemsDecision,
        { isLoading: isSavingBusinessRequestItemsDecision },
    ] = useSaveRequestToDoItemDecision(id)

    const {
        hasAccessToRequestItemDelegateControl,
        canSeeRiskViolationsByRisks,
        canSeeToDoListTimeConstraint,
    } = useSubcomponents()

    const [state, dispatch] = useReducer(reducers, [])

    const [expanded, setExpanded] = useState([])

    const isExpanded = (item) => expanded.find((i) => i.id === item.id)

    const handleExpand = (item, section) => {
        const expandedIndex = expanded.findIndex((i) => i.id === item.id)

        const expandedItem = expanded.find((i) => i.id === item.id)

        let newExpanded = []

        if (!expandedItem) {
            newExpanded = newExpanded.concat(expanded, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section !== section) {
            let filteredArray = expanded.filter((i) => i.id !== item.id)
            newExpanded = newExpanded.concat(filteredArray, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section === section) {
            newExpanded = [...expanded]
            newExpanded.splice(expandedIndex, 1)
        }

        setExpanded(newExpanded)
    }

    const renderItemTypeActionFriendlyName = (item) => {
        if (item.assignee) {
            return (
                <Fragment>
                    {item.itemTypeActionFriendlyName} ({item.assignee})
                </Fragment>
            )
        }
        return item.itemTypeActionFriendlyName
    }

    const hasAccessToTimeConstraint = (item) => {
        return canSeeToDoListTimeConstraint && item.requestPolicyId
    }

    const attributes = [
        {
            style: {
                minWidth: '200px',
            },
            label: t('MyTasks_Name'),
            resolve: (item) => (
                <Link
                    to={`/myTasks/businessRequestItems?id=${item.id}`}
                    style={{
                        color: '#01ae8f',
                        fontWeight: 'bold',
                    }}
                >
                    {renderItemTypeActionFriendlyName(item)}
                </Link>
            ),
        },
        {
            name: 'resourceRequested',
            label: t('MyTasks_TargetResource'),
            style: {
                color: '#5d6870',
                minWidth: '120px',
            },
        },
        {
            name: 'currentApprovalStepName',
            label: t('MyTasks_CurrentStep'),
            style: {
                minWidth: '120px',
            },
        },
    ]

    const headings = attributes.map((a) => a.label)

    const renderCollapsedContent = (item) => {
        const expandedItem = isExpanded(item)

        const clientSideDecisionId = state.find((s) => s.id === item.id)
            ? state.find((s) => s.id === item.id).decisionId
            : item.decisionTakenId

        const clientSideComment = state.find((s) => s.id === item.id)
            ? state.find((s) => s.id === item.id).comment
            : item.decisionComment

        let expandedSection
        if (expandedItem && expandedItem.section === 'approvers') {
            expandedSection = (
                <Box classes={{ root: classes.approversContainer }}>
                    <BusinessRequestItemApprovers
                        requestId={id}
                        itemId={item.id}
                        totalCount={item.approverCount}
                    />
                </Box>
            )
        } else if (expandedItem && expandedItem.section === 'comments') {
            expandedSection = (
                <BusinessRequestItemComments requestId={id} itemId={item.id} />
            )
        } else if (expandedItem && expandedItem.section === 'violations') {
            expandedSection = (
                <RisksWithViolations
                    page={'Requests'}
                    requestId={id}
                    requestIdOrItemId={item.id}
                    useDataHook={useRequestItemRisks}
                />
            )
        }

        return (
            <>
                {expandedItem && expandedSection}

                {clientSideDecisionId !== null && (
                    <Box padding="30px">
                        <DecisionComment
                            initialValue={clientSideComment ?? ''}
                            maxCharacters={APPROVAL_COMMENT_MAX_LENGTH}
                            onBlur={(e) => {
                                let decisionComment = e.target.value
                                handleDecideWithComment(item, decisionComment)
                            }}
                        />
                    </Box>
                )}
            </>
        )
    }

    const distinctDecisions = []
    if (data && data.length > 0) {
        data.map((d) => d.decisions)
            .reduce((current, next) => current.concat(next))
            .forEach((d) => {
                if (
                    !distinctDecisions.find(
                        (a) => a.decisionId === d.decisionId,
                    )
                ) {
                    distinctDecisions.push(d)
                }
            })
    }

    const handleDecideAll = (decisionMade) => {
        const decisionId = decisionMade ? decisionMade.decisionId : null

        const itemsRequiringManualDecisions = config.ENABLE_APPROVAL_WORKFLOWS
            ? data.filter((i) => !i.decisionWorkflowName)
            : data

        let items
        if (decisionId) {
            items = itemsRequiringManualDecisions.filter((i) =>
                i.decisions.find((d) => d.decisionId === decisionId),
            )
        } else {
            items = itemsRequiringManualDecisions
        }

        if (callApiOnEveryChange) {
            const saveDecisionRequest = items
                .filter((i) => i.decisionTakenId !== decisionId)
                .map((i) => ({
                    decisionId: decisionId,
                    id: i.id,
                    stepId: i.currentApprovalStepId,
                }))

            if (saveDecisionRequest.length > 0) {
                saveBusinessRequestItemsDecision(saveDecisionRequest)
            }
        } else {
            if (decisionId === null) {
                dispatch({
                    type: 'CLEAR',
                })
            } else {
                dispatch({
                    type: 'SET_MULTIPLE_DECISIONS',
                    payload: {
                        items,
                        decisionId: decisionId,
                    },
                })
            }
        }
    }

    const handleDecideOne = (item, decisionMade) => {
        const decisionId = decisionMade ? decisionMade.decisionId : null
        if (callApiOnEveryChange) {
            const saveDecisionRequest = {
                decisionId: decisionId,
                id: item.id,
                stepId: item.currentApprovalStepId,
            }

            if (decisionId !== item.decisionTakenId) {
                saveBusinessRequestItemsDecision([saveDecisionRequest])
            }
        } else {
            dispatch({
                type: 'SET_DECISION',
                payload: {
                    item,
                    decisionId: decisionId,
                },
            })
        }
    }

    const handleDecideWithComment = (item, comment) => {
        if (callApiOnEveryChange) {
            const saveDecisionRequest = {
                id: item.id,
                decisionId: item.decisionTakenId,
                stepId: item.currentApprovalStepId,
                comment: comment,
            }

            saveBusinessRequestItemsDecision([saveDecisionRequest])
        } else {
            dispatch({
                type: 'SET_COMMENT',
                payload: {
                    item,
                    comment: comment,
                },
            })
        }
    }

    const notSubmittable = state.filter(
        (item) =>
            isWhitespace(item.comment) ||
            item.comment?.length > APPROVAL_COMMENT_MAX_LENGTH,
    )

    const bindActionItems = (item) => {
        const expandedItem = isExpanded(item)
        return (
            <Box className={classes.actionbutton}>
                <>
                    {businessRequest.status !== 'Open_PendingApproval' && (
                        <Box className={classes.approverbtn}>
                            <Button.Expand
                                type="Approvers"
                                handleExpand={() =>
                                    handleExpand(item, 'approvers')
                                }
                                expanded={
                                    expandedItem &&
                                    expandedItem.section === 'approvers'
                                }
                            />
                        </Box>
                    )}
                </>
                <>
                    <Box className={classes.commentsbtn}>
                        <Button.Expand
                            type="Comments"
                            handleExpand={() => handleExpand(item, 'comments')}
                            expanded={
                                expandedItem &&
                                expandedItem.section === 'comments'
                            }
                        />
                    </Box>
                </>

                <>
                    {!hasAccessToRequestItemDelegateControl ||
                    (!item.allowAddApprover && !item.allowAssignApprover) ? (
                        <></>
                    ) : (
                        <Box className={classes.requestsbtn}>
                            <DelegateMenuIconButton
                                page="Requests"
                                isApprover={item.isApprover}
                                requestId={businessRequest.id}
                                requestItemId={item.id}
                                currentApprovalStepId={
                                    item.currentApprovalStepId
                                }
                                assignedByPersonId={item.assignedByPersonId}
                                assignedByPersonFriendlyName={
                                    item.assignedByPersonFriendlyName
                                }
                                claimedApproverId={item.claimedApproverId}
                                claimedApproverFriendlyName={
                                    item.claimedApproverFriendlyName
                                }
                                allowAddApprover={item.allowAddApprover}
                                allowAssignApprover={item.allowAssignApprover}
                            />
                        </Box>
                    )}
                </>

                <>
                    {hasAccessToTimeConstraint(item) ? (
                        <Box className={classes.timeconstraintbtn}>
                            <Button.TimeConstraint item={item} id={id} />
                        </Box>
                    ) : (
                        <></>
                    )}
                </>

                <>
                    {item.riskCount <= 0 || !canSeeRiskViolationsByRisks ? (
                        <></>
                    ) : (
                        <Box className={classes.violationbtn}>
                            <Button.Expand
                                type={'Violations'}
                                handleExpand={() =>
                                    handleExpand(item, 'violations')
                                }
                                expanded={
                                    expandedItem &&
                                    expandedItem.section === 'violations'
                                }
                            />
                        </Box>
                    )}
                </>
            </Box>
        )
    }

    return (
        <Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        <TableCell
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                            style={{ minWidth: '160px' }}
                            padding="checkbox"
                        >
                            {!config.ENABLE_APPROVAL_WORKFLOWS ||
                            (data &&
                                !data.some(
                                    (i) =>
                                        i.decisionWorkflowName ||
                                        i.isViolatingItem,
                                )) ? (
                                <DecisionMenu
                                    value={null}
                                    options={distinctDecisions}
                                    onChange={handleDecideAll}
                                />
                            ) : (
                                <Fragment />
                            )}
                        </TableCell>

                        {headings.map((heading, index) => (
                            <TableCell
                                key={index}
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                                align={heading.align}
                            >
                                {heading}
                            </TableCell>
                        ))}
                    </TableRow>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={headings.length + 2}>
                                <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    height={64}
                                />
                            </TableCell>
                        </TableRow>
                    ) : data.length === 0 ? (
                        <TableRow className={classes.tableRow} hover={false}>
                            <TableCell
                                className={classes.tableCell}
                                colSpan={headings.length + 2}
                                style={{ textAlign: 'center' }}
                            >
                                <Trans i18nKey="MyTasks_NoDataFound" />
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {data.map((item) => (
                                <React.Fragment key={item.id}>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell
                                            className={classes.tableCell}
                                        >
                                            {config.ENABLE_APPROVAL_WORKFLOWS &&
                                            item.decisionWorkflowName ? (
                                                <WorkflowDecisionModal
                                                    page={'Requests'}
                                                    requestId={
                                                        businessRequest.id
                                                    }
                                                    itemId={item.id}
                                                    currentApprovalStepId={
                                                        item.currentApprovalStepId
                                                    }
                                                    decisionWorkflowName={
                                                        item.decisionWorkflowName
                                                    }
                                                />
                                            ) : item.isViolatingItem ? (
                                                <ViolatingItemDecision
                                                    item={item}
                                                />
                                            ) : (
                                                <DecisionMenu
                                                    value={
                                                        state.find(
                                                            (s) =>
                                                                s.id == item.id,
                                                        )
                                                            ? state.find(
                                                                  (s) =>
                                                                      s.id ==
                                                                      item.id,
                                                              ).decisionId
                                                            : item.decisionTakenId
                                                    }
                                                    options={item.decisions}
                                                    onChange={(decisionMade) =>
                                                        handleDecideOne(
                                                            item,
                                                            decisionMade,
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        {attributes.map((a, index) => (
                                            <TableCell
                                                key={`${item.id}${index}`}
                                                className={classes.tableCell}
                                                style={a.style}
                                            >
                                                {a.resolve
                                                    ? a.resolve(item)
                                                    : !isNilOrEmpty(
                                                          item[a.name],
                                                      )
                                                    ? item[a.name]
                                                    : '-'}
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            {bindActionItems(item)}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow
                                        hover={false}
                                        className={
                                            Boolean(isExpanded(item))
                                                ? classes.expansionRowActive
                                                : classes.expansionRow
                                        }
                                    >
                                        <TableCell
                                            colSpan={headings.length + 2}
                                            className={
                                                Boolean(isExpanded(item))
                                                    ? classes.expansionCellActive
                                                    : classes.expansionCell
                                            }
                                        >
                                            <Collapse
                                                in={
                                                    Boolean(isExpanded(item)) ||
                                                    Boolean(
                                                        state.find(
                                                            (s) =>
                                                                s.id == item.id,
                                                        )
                                                            ? state.find(
                                                                  (s) =>
                                                                      s.id ==
                                                                      item.id,
                                                              ).decisionId
                                                            : item.decisionTakenId,
                                                    )
                                                }
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                {renderCollapsedContent(item)}
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </TableHead>
            </Table>
            <Box display="flex">
                {data &&
                    data.length > 0 &&
                    !callApiOnEveryChange &&
                    state.length > 0 && (
                        <Box marginX="30px" marginY="15px" maxWidth="140px">
                            <Button.Submit
                                disabled={!isNilOrEmpty(notSubmittable)}
                                loading={isSavingBusinessRequestItemsDecision}
                                onClick={() => {
                                    saveBusinessRequestItemsDecision(
                                        state,
                                    ).then(() => {
                                        dispatch({
                                            type: 'CLEAR',
                                        })
                                        onSuccess()
                                    })
                                }}
                            >
                                {t('MyTasks_Submit')}
                                {totalCount && (
                                    <Box component="span" marginLeft="8px">
                                        ({state.length}/{totalCount})
                                    </Box>
                                )}
                            </Button.Submit>
                        </Box>
                    )}
            </Box>
        </Box>
    )
}
