import {
    Box,
    Collapse,
    createStyles,
    Link,
    makeStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { FC, Fragment, useState } from 'react'
import { RiskViolationStatus } from './RiskViolationStatus'
import { FunctionsGranted } from './FunctionsGranted'
import { ArrowIcon } from 'packages/eid-icons'
import { ApproveRisk } from '../PendingRiskItem/ApproveRisk'
import RevokeButton from '../Button/RevokeButton'
import { mobileScreenWidth } from 'utils'
import { useSubmitRiskItemDecision } from 'hooks'
import { Tooltip } from 'packages/eid-ui'
import classNames from 'classnames'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [`@media (max-width:${mobileScreenWidth})`]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        separator: {
            color: theme.palette.grey[300],
            fontSize: '48px',
            fontWeight: 300,
            lineHeight: 'normal',
            margin: '0px 12px',

            [`@media (max-width:${mobileScreenWidth})`]: {
                margin: '6px 0px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '32px',
            },
        },
        ampersand: {
            [`@media (max-width:${mobileScreenWidth})`]: {
                margin: '0px 8px',
            },
        },
        separatorLine: {
            display: 'none',
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'flex',
                height: '2px',
                backgroundColor: '#d8d8dd',
                width: '100%',
                margin: 'auto',
            },
        },
        leftRightBox: {
            display: 'flex',
            flexDirection: 'column',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            overflow: 'auto',
            width: '50%',
            paddingRight: '15px',
            marginBottom: '20px',
        },
        source: {
            color: '#5d6870',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginRight: '8px',
        },
        reason: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '8px',
            fontSize: '12px',
            fontWeight: 'bold',
        },
        riskReasonTag: {
            backgroundColor: '#ffffff',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px 8px',
            textTransform: 'uppercase',
            color: '#000',
            border: '1px solid #aab0b4',
            borderRadius: '5px',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
        },
        requestedResource: {
            fontWeight: 'bold',
        },
        currentRequestColor: {
            color: '#3eaa05',
        },
        differentRequestColor: {
            color: '#453fbb',
        },
        membershipColor: {
            color: '#ff6100',
        },
        actionArea: {
            backgroundColor: '#ffffff',
            width: '100%',
            minHeight: '40px',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            paddingTop: '32px',
            paddingBottom: '8px',
            '& > button': {
                margin: '16px 16px 0 16px',
            },
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '& > button': {
                    width: '100%',
                    maxWidth: '420px',
                },
            },
        },
        functionsAndDetails: {
            position: 'relative',
            backgroundColor: '#efeff1',
            margin: '0px -16px',
            borderTop: '1px solid #efeff1',
            marginTop: '32px',
        },
        showMoreButton: {
            position: 'absolute',
            bottom: '-14px',
            left: 'calc(50% - 58px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: '#307fc1',
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: '#307fc1',
                    height: '20px',
                },
            },
        },
        riskNode: {
            width: '1px',
            marginLeft: '-10px',
        },
    }),
)

const RiskViolationLeftRight: FC<any> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { requestId, itemId, violation, page, showDecisions } = props

    const leftRisk: any = violation.riskDetails
    const rightRisk: any = violation.segregatedRiskDetails

    const [
        submitRiskDecision,
        { isLoading: isSubmitting },
    ] = useSubmitRiskItemDecision(
        page as 'Requests' | 'RequestItems',
        requestId,
        itemId,
    )

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    const renderOneSide = (currentRisk: any, left: boolean) => {
        const isRequestAccess = currentRisk.riskReason === 'RequestAccess'
        const isRequest = currentRisk.riskReason === 'BusinessRequest'
        const isMembership = currentRisk.riskReason === 'Membership'

        const isCurrentRequest = requestId === currentRisk.requestId
        const isDifferentRequest = requestId !== currentRisk.requestId

        let label: string
        if (isRequestAccess) {
            label = 'MyTasks_RequestAccess'
        } else if (isMembership) {
            label = 'MyTasks_Membership'
        } else if (isCurrentRequest) {
            label = 'MyTasks_ThisRequest'
        } else {
            label = 'MyTasks_BusinessRequest'
        }

        const renderLeftResourceRequested = () => {
            if (violation.extendedRiskDescription) {
                return (
                    <Box
                        className={classNames(classes.requestedResource, {
                            [classes.currentRequestColor]: isCurrentRequest,
                            [classes.membershipColor]: isMembership,
                            [classes.differentRequestColor]: isDifferentRequest,
                        })}
                    >
                        <Tooltip title={violation.extendedRiskDescription}>
                            <span>{leftRisk.riskFunctionAssignee}</span>
                        </Tooltip>
                    </Box>
                )
            } else {
                return (
                    <Box
                        className={classNames(classes.requestedResource, {
                            [classes.currentRequestColor]: isCurrentRequest,
                            [classes.membershipColor]: isMembership,
                            [classes.differentRequestColor]: isDifferentRequest,
                        })}
                    >
                        {leftRisk.riskFunctionAssignee}
                    </Box>
                )
            }
        }

        const renderRequestNumber = () => {
            if (isCurrentRequest) {
                return (
                    <Fragment>
                        {currentRisk.requestNumber && (
                            <Fragment>#{currentRisk.requestNumber}</Fragment>
                        )}
                    </Fragment>
                )
            } else {
                return (
                    <Link
                        color={'inherit'}
                        underline={'always'}
                        target={'_blank'}
                        href={`/myRequests/businessRequests?id=${currentRisk.requestId}`}
                    >
                        {currentRisk.requestNumber
                            ? `#${currentRisk.requestNumber}`
                            : t('MyTasks_GoToRequest')}
                    </Link>
                )
            }
        }

        return (
            <Box className={classes.leftRightBox}>
                <Box
                    className={classNames(classes.requestedResource, {
                        [classes.currentRequestColor]: isCurrentRequest,
                        [classes.membershipColor]: isMembership,
                        [classes.differentRequestColor]: isDifferentRequest,
                    })}
                >
                    {left
                        ? renderLeftResourceRequested()
                        : currentRisk.riskFunctionAssignee}
                </Box>
                <span className={classes.riskNode} id={props.riskNodeID}></span>
                <Box className={classes.reason}>
                    {left && (
                        <span className={classes.source}>
                            {t('MyTasks_Source')}
                        </span>
                    )}
                    <Box className={classes.riskReasonTag}>{t(label)}</Box>
                    {isRequest && (
                        <Box
                            component={'span'}
                            className={classNames({
                                [classes.currentRequestColor]: isCurrentRequest,
                                [classes.membershipColor]: isMembership,
                                [classes.differentRequestColor]: isDifferentRequest,
                            })}
                        >
                            {renderRequestNumber()}
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    return (
        <Fragment>
            <Box className={classes.root}>
                {renderOneSide(leftRisk, true)}
                {violation.riskType === 'SegregationOfDuties' && (
                    <Fragment>
                        <Box className={classes.separator}>
                            <Box className={classes.separatorLine} />
                            <Box className={classes.ampersand}>&</Box>
                            <Box className={classes.separatorLine} />
                        </Box>
                        {renderOneSide(rightRisk, false)}
                    </Fragment>
                )}
            </Box>
            <Box>
                <RiskViolationStatus risk={violation} />
            </Box>
            {showDecisions && violation.decisions && (
                <Box className={classes.actionArea}>
                    {violation.decisions?.map((d: any) => (
                        <Fragment key={d.decisionId}>
                            {d.decisionAction === 'Continue' ? (
                                <ApproveRisk
                                    globalRiskId={violation.globalRiskId}
                                    onSubmit={(value) => {
                                        const approveReq = {
                                            ...value,
                                            riskId: violation.id,
                                            stepId:
                                                violation.currentApprovalStepId,
                                            decisionId: d.decisionId,
                                        }
                                        return submitRiskDecision(approveReq)
                                    }}
                                    decisionFriendlyName={
                                        d.decisionFriendlyName
                                    }
                                    isLoading={isSubmitting}
                                />
                            ) : (
                                <RevokeButton
                                    size={'small'}
                                    onClick={() => {
                                        const revokeReq = {
                                            riskId: violation.id,
                                            stepId:
                                                violation.currentApprovalStepId,
                                            decisionId: d.decisionId,
                                        }
                                        submitRiskDecision(revokeReq)
                                    }}
                                    loading={isSubmitting}
                                >
                                    {d.decisionFriendlyName}
                                </RevokeButton>
                            )}
                        </Fragment>
                    ))}
                </Box>
            )}
            {props.showFunctions && (
                <Box className={classes.functionsAndDetails}>
                    <Box className={classes.showMoreButton}>
                        <button onClick={handleChange}>
                            {checked
                                ? t('MyTasks_Hide')
                                : t('MyTasks_ShowMore')}
                            <ArrowIcon direction={checked ? 'up' : 'down'} />
                        </button>
                    </Box>

                    <Collapse in={checked} collapsedHeight={0}>
                        <FunctionsGranted risk={violation} fetch={checked} />
                    </Collapse>
                </Box>
            )}
        </Fragment>
    )
}

RiskViolationLeftRight.defaultProps = {
    showFunctions: true,
}

export { RiskViolationLeftRight }
