import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles, useMediaQuery } from '@material-ui/core'
import { PersonCardSkeleton } from 'components'
import { useRequestItemRisks, useRequestItemsByItemType } from 'hooks'
import ResourceTypeCard from './ResourceTypeCard'
import { smallScreenWidth } from 'utils'
import { Pagination } from 'packages/eid-ui'
import { ItemApprovers } from './ItemApprovers'
import { ItemComments } from './ItemComments'
import { RisksWithViolations } from 'components/V2/RisksWithViolations'

const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        borderBottom: 'solid 1px #d8dadd',
    },
    paginationContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '20px 60px',
    },
    itemsContainer: {
        position: 'relative',
        maxWidth: '1024px',
        maxHeight: '620px',
        overflow: 'auto',
        paddingBottom: '18px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        [`@media (max-width:${smallScreenWidth})`]: {
            width: '100vw',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: 'center',
        },
    },
    itemContainer: {
        marginLeft: '20px',
        marginRight: '10px',
        marginTop: '20px',
        width: '304px',
    },
    collapsibleContainer: {
        backgroundColor: '#ededef',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        width: '100%',
        marginTop: '-2px',
    },
})

const defaultTake = 3

const ItemTypeBusinessRequestItems = ({
    businessRequest,
    requestId,
    itemTypeId,
    currentTab,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const isLargeScreen = useMediaQuery(`(min-width:${smallScreenWidth})`)

    const [page, setPage] = React.useState(1)

    useEffect(() => {
        setPage(1)
    }, [currentTab])

    const itemStatus = currentTab === 'All' ? null : currentTab
    const { latestData, isLoading } = useRequestItemsByItemType(
        requestId,
        itemTypeId,
        itemStatus,
        (page - 1) * defaultTake,
        defaultTake,
    )

    const data = latestData ? latestData.data : undefined

    const [expansion, setExpansion] = useState({
        type: null,
        item: null,
    })

    const handlePageChange = (_, value) => {
        setPage(value)
    }

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / defaultTake)
        : 0

    const paginationSteppers = (
        <Box className={classes.paginationContainer}>
            {
                <Pagination
                    count={numberOfPages}
                    size="medium"
                    page={page}
                    onChange={handlePageChange}
                />
            }
        </Box>
    )

    const renderExpandedSection = (item) => (
        <Box className={classes.collapsibleContainer}>
            {expansion.type === 'approvers' ? (
                <ItemApprovers
                    requestId={item.businessRequestId}
                    itemId={item.id}
                    totalCount={item.approverCount}
                />
            ) : expansion.type === 'comments' ? (
                <ItemComments requestId={requestId} itemId={item.id} />
            ) : (
                <Box paddingX={'12px'}>
                    <RisksWithViolations
                        page={'Requests'}
                        requestId={requestId}
                        requestIdOrItemId={item.id}
                        useDataHook={useRequestItemRisks}
                    />
                </Box>
            )}
        </Box>
    )

    const isSectionExpanded = (section, item) =>
        expansion.item?.id === item.id && expansion.type === section

    const onSectionExpansion = (section, item) => {
        if (expansion?.type === section && expansion.item?.id === item.id) {
            setExpansion({
                type: null,
                item: null,
            })
        } else {
            setExpansion({
                type: section,
                item,
            })
        }
    }

    return (
        <Box className={classes.container}>
            {numberOfPages > 1 && paginationSteppers}

            <Box className={classes.itemsContainer}>
                {!data || isLoading ? (
                    <>
                        <PersonCardSkeleton marginX="10px" />
                        <PersonCardSkeleton marginX="10px" />
                        <PersonCardSkeleton marginX="10px" />

                        <PersonCardSkeleton marginX="10px" />
                        <PersonCardSkeleton marginX="10px" />
                        <PersonCardSkeleton marginX="10px" />
                    </>
                ) : data.length === 0 ? (
                    <Box style={{ padding: '20px 10px 0px 31px' }}>
                        {t('MyTasks_NoBusinessRequestItemsFound')}
                    </Box>
                ) : (
                    data.map((item) => (
                        <React.Fragment key={item.id}>
                            <Box className={classes.itemContainer}>
                                <ResourceTypeCard
                                    businessRequest={businessRequest}
                                    commentsExpanded={isSectionExpanded(
                                        'comments',
                                        item,
                                    )}
                                    onCommentsExpansion={() =>
                                        onSectionExpansion('comments', item)
                                    }
                                    approversExpanded={isSectionExpanded(
                                        'approvers',
                                        item,
                                    )}
                                    onApproversExpansion={() =>
                                        onSectionExpansion('approvers', item)
                                    }
                                    violationsExpanded={isSectionExpanded(
                                        'violations',
                                        item,
                                    )}
                                    onViolationsExpansion={() =>
                                        onSectionExpansion('violations', item)
                                    }
                                    item={item}
                                    itemTypeId={itemTypeId}
                                />
                            </Box>
                            {expansion.item &&
                                !isLargeScreen &&
                                expansion.item.id === item.id &&
                                renderExpandedSection(expansion.item)}
                        </React.Fragment>
                    ))
                )}
                {expansion.item &&
                    isLargeScreen &&
                    renderExpandedSection(expansion.item)}
            </Box>
        </Box>
    )
}

export default ItemTypeBusinessRequestItems
