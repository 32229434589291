import React from 'react'
import Button from './index'
import { Typography, Box } from '@material-ui/core'

const styles = {
    normal: {
        width: '100%',
        height: '100%',
        backgroundColor: '#307fc1',
        color: '#fff',
        border: 'solid 1px #307fc1',
        '&:hover': {
            backgroundColor: '#307fc1',
        },
    },
    disabled: {
        backgroundColor: '#c5cbda',
        border: 'solid 1px #c5cbda',
        color: '#ffffff !important',
        opacity: '0.8',
    },

    label: {
        fontSize: '11px',
        lineHeight: 'normal',
    },
}

const CheckInListingButton = ({ children, label, ...rest }) => {
    return (
        <Button
            style={styles.normal}
            disabledStyles={styles.disabled}
            {...rest}
        >
            {!children ? (
                <>
                    <Typography style={styles.label}>{label}</Typography>
                </>
            ) : (
                children
            )}
        </Button>
    )
}

export default CheckInListingButton
