import React, { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import DropdownFilter from './DropdownFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import useSubcomponents from 'useSubcomponents'

const DropdownMenu = ({ routeOptions, selectedParentRoute, onChange }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const [open, setOpen] = useState(false)
    const pathnames = pathname.split('/')
    const areaName = pathnames[1]
    const view = pathnames[2]

    const {
        hasAccessToRequestViewMyRequestsPage,
        hasAccessToItemViewMyRequestsPage,

        hasAccessToRequestViewMyTasksPage,
        hasAccessToItemViewMyTasksPage,

        hasAccessToRequestViewAllRequestsPage,
        hasAccessToItemViewAllRequestsPage,
    } = useSubcomponents()

    const hasAccessToRequestView = (name) => {
        if (name.toLowerCase() === 'myrequests')
            return hasAccessToRequestViewMyRequestsPage
        else if (name.toLowerCase() === 'mytasks')
            return hasAccessToRequestViewMyTasksPage
        else return hasAccessToRequestViewAllRequestsPage
    }

    const hasAccessToItemView = (name) => {
        if (name.toLowerCase() === 'myrequests')
            return hasAccessToItemViewMyRequestsPage
        else if (name.toLowerCase() === 'mytasks')
            return hasAccessToItemViewMyTasksPage
        else return hasAccessToItemViewAllRequestsPage
    }

    return (
        <>
            <DropdownFilter
                label={t(selectedParentRoute.title)}
                setOpen={setOpen}
                open={open}
                style={{ height: '60px', minWidth: '230px' }}
                anchorPosition={{ top: '60px' }}
            >
                {routeOptions.map((routeOption, index) => (
                    <DropdownFilter.Item
                        selected={routeOption.route === `/${areaName}`}
                        onClick={(e) => {
                            setOpen((prev) => !prev)

                            if (onChange) {
                                onChange(routeOption.name)
                                return
                            }

                            let path

                            if (view.toLowerCase() === 'businessrequests') {
                                if (hasAccessToRequestView(routeOption.name)) {
                                    path = 'businessRequests'
                                } else {
                                    path = 'businessRequestItems'
                                }
                            } else if (
                                view.toLowerCase() === 'businessrequestitems'
                            ) {
                                if (hasAccessToItemView(routeOption.name)) {
                                    path = 'businessRequestItems'
                                } else {
                                    path = 'businessRequests'
                                }
                            }

                            history.push(`${routeOption.route}/${path}`)
                        }}
                        key={index}
                    >
                        {routeOption.icon && (
                            <Icon name={routeOption.icon} color="#919193" />
                        )}
                        {t(routeOption.title)}
                    </DropdownFilter.Item>
                ))}
            </DropdownFilter>
        </>
    )
}

export { DropdownMenu }
