import React, { useState, useRef } from 'react'
import Button from './index'
import { Typography, Box, Backdrop, styled, MenuItem } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'

const StyledBackdrop = styled(Backdrop)(() => ({
    zIndex: '1201',
}))

const StyledMenuItem = styled(MenuItem)({
    height: '30px',
})

const CheckOutDetailButton = ({
    children,
    disabled,
    onButtonClick,
    label,
    checkoutOptions,
    ...rest
}) => {
    const [open, setOpen] = useState(false)
    const closeMenu = () => {
        setOpen((prev) => !prev)
    }
    const anchorRef = useRef(null)

    const styles = {
        normal: {
            width: '100%',
            height: '100%',
            backgroundColor: '#307fc1',
            color: '#fff',
            border: 'solid 1px #307fc1',
            padding: '0px',
            zIndex: open ? '1202' : '0',
            '&:hover': {
                backgroundColor: '#307fc1',
            },
            '& .MuiButton-endIcon': {
                borderLeft: '1px solid rgba(255, 255, 255, 0.25)',
                height: '100%',
                marginLeft: '0px',
                marginRight: '0px',
                alignItems: 'center',
            },
            '& .MuiButton-label': {
                height: '100%',
            },
        },
        disabled: {
            backgroundColor: '#fff',
            border: 'solid 1px #ebe6e8',
            color: '#3f3f55 !important',
        },
        labelContainer: {
            width: '100%',
        },
        label: {
            fontSize: '16px',
            marginRight: '20px',
        },
        icon: {
            borderLeft: '1px solid rgba(255, 255, 255, 0.25)',
            display: 'flex',
            alignItems: 'center',
            width: '20%',
        },
        dropdownAnchor: {
            position: 'absolute',
            top: '25px',
            left: '28px',
        },
    }

    return (
        <>
            <Button
                style={styles.normal}
                disabled={disabled}
                disabledStyles={styles.disabled}
                startIcon={
                    <Icon
                        style={{ marginLeft: '30px' }}
                        name="CheckOut"
                        color="#ffffff"
                        direction={'left'}
                    />
                }
                endIcon={
                    !disabled && (
                        <Box
                            onClick={() => {
                                setOpen((prev) => !prev)
                            }}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                width: '30px',
                                paddingLeft: '5px',
                            }}
                        >
                            <Icon
                                name="ArrowSmall"
                                color="#ffffff"
                                width="20px"
                                fill="#ffffff"
                                direction="down"
                                height="7px"
                            />
                            <span
                                ref={anchorRef}
                                style={styles.dropdownAnchor}
                            />
                        </Box>
                    )
                }
                {...rest}
            >
                {!children ? (
                    <>
                        <Box
                            style={styles.labelContainer}
                            onClick={onButtonClick}
                        >
                            <Typography style={styles.label}>
                                {label}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    children
                )}
            </Button>
            <StyledBackdrop
                sx={{ color: '#fff' }}
                open={open}
                onClick={closeMenu}
            />

            <Dropdown
                anchorEl={anchorRef.current}
                showCone={true}
                keepMounted
                open={open}
                onClose={closeMenu}
                listStyles={{
                    paddingBottom: '0px',
                    width: '200px',
                    '& > ul': {
                        paddingBottom: '7px',
                        paddingTop: '0px',
                    },
                }}
                iconPosition={{
                    top: '-5px',
                    right: '0px',
                }}
                width="auto"
                disableScrollLock={false}
            >
                {checkoutOptions.map((op, i) => {
                    return (
                        <StyledMenuItem
                            onClick={() => {
                                op.onClick()
                                closeMenu()
                            }}
                            key={i}>
                            {op.label}
                        </StyledMenuItem>
                    )
                })}
            </Dropdown>
        </>
    )
}

export default CheckOutDetailButton
