import React from 'react'
import { Icon, EidLogoIcon } from 'packages/eid-icons'
import { Header } from 'packages/eid-ui'
import { Link } from '@material-ui/core'
import LanguageMenu from './LanguageMenu'
import AccountDropdown from './AccountDropdown'

const itemStyles = {
    minWidth: '30px',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const AppMobileHeader = ({ config }) => {
    const height = config.mobile?.height

    return (
        <Header.Mobile bgColor={config.bgColor} height={height}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                }}
            >
                <Link
                    href="/"
                    underline="none"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Header.Item style={{ ...itemStyles, padding: 0 }}>
                        {config.appLogo.type === 'base64' ? (
                            <img
                                style={config.appLogo.style}
                                src={config.appLogo.value}
                                alt={config.appLogo.name}
                            />
                        ) : config.showEidLogo ? (
                            <EidLogoIcon />
                        ) : (
                            <Icon name={config.appLogo.value} />
                        )}
                    </Header.Item>
                </Link>

                <Header.RightSection>
                    <Header.Item style={itemStyles}>
                        <LanguageMenu showCone={false} anchorPosition={{
                        right: '-32px', top: '54px',
                    }}/>
                    </Header.Item>
                    <Header.Item style={itemStyles}>
                        <AccountDropdown />
                    </Header.Item>
                </Header.RightSection>
            </div>
        </Header.Mobile>
    )
}
export default AppMobileHeader
