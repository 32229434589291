import { useCallback, useEffect } from 'react'
import { useAuthState } from '../auth/context'
import axios from 'axios'
import config from 'config'
import { useHistory } from 'react-router'

/**
 * Returns a function that can be used to call an API.
 * This function wraps the axios instance.
 */
const useAxios = (baseUrl = config.API_URL) => {
    const history = useHistory()
    const [{ accessToken }] = useAuthState()

    const callApi = useCallback(
        async ({ headers, ...rest }) => {
            try {
                const { data } = await axios({
                    baseURL: baseUrl,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                        ...headers,
                    },
                    ...rest,
                    validateStatus: (status) => status >= 200 && status <= 299,
                })
                return data
            } catch (err) {
                if (err && err.response && err.response.status === 401) {
                    history.push('/sessionExpired')
                } else if (err && err.response && err.response.status === 503) {
                    history.push('/underMaintenance')
                }
                throw err
            }
        },
        [accessToken],
    )

    return callApi
}
export default useAxios
